import React from 'react';
import * as Button from '../../../Button';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Fade from 'react-reveal/Fade';

export default function ClientsSection() {
	const brands = useStaticQuery(graphql`
		query BrandsQuery {
			elodie: file(name: { eq: "elodie-logo" }) {
				childImageSharp {
					fluid(maxWidth: 4000, quality: 60) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			bmw: file(name: { eq: "bmw-logo" }) {
				childImageSharp {
					fluid(maxWidth: 4000, quality: 60) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			vectr: file(name: { eq: "vectr-logo" }) {
				childImageSharp {
					fluid(maxWidth: 4000, quality: 60) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			fuin: file(name: { eq: "fuin-logo" }) {
				childImageSharp {
					fluid(maxWidth: 4000, quality: 60) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			hkpi: file(name: { eq: "hkpi-logo" }) {
				childImageSharp {
					fluid(maxWidth: 4000, quality: 60) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			huma: file(name: { eq: "huma-logo" }) {
				childImageSharp {
					fluid(maxWidth: 4000, quality: 60) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
	`);
	return (
		<section id="clients" className="flex bg-brand-gray lg:h-full">
			<div className="container m-auto">
				<div className="flex lg:w-full lg:flex-col self-center">
					<Fade bottom>
						<div className="w-5/12 px-24 py-32 self-center lg:w-full lg:px-20 md:px-10">
							<h3 className="title uppercase mb-8">
								Web applications & E-commerce is what we do best.
							</h3>
							<p className="body mb-16">
								We are couple of website guys got together and began sharing our
								web culture knowledge helping entrepreneurs take their
								businesses online.
							</p>
							<Button.Primary>Learn More</Button.Primary>
						</div>
					</Fade>
					<div className="w-7/12 p-24 lg:w-full lg:px-20 md:px-10 lg:py-0 lg:pb-20 self-center">
						<Fade bottom>
							<div className="flex flex-wrap">
								<Brand
									logo={brands.vectr.childImageSharp.fluid}
									link="https://vectr.co"
								/>
								<Brand
									logo={brands.bmw.childImageSharp.fluid}
									link="https://along.bmwhk.com/"
								/>
								<Brand
									logo={brands.fuin.childImageSharp.fluid}
									link="https://fuinrealestate.com/"
								/>
								<Brand
									logo={brands.huma.childImageSharp.fluid}
									link="https://huma.com/"
								/>
								<Brand
									logo={brands.hkpi.childImageSharp.fluid}
									link="https://www.hkpi.com.hk/"
								/>
								<Brand
									logo={brands.elodie.childImageSharp.fluid}
									link="https://elodie.games/index.html"
								/>
							</div>
						</Fade>
					</div>
				</div>
			</div>
		</section>
	);
}

function Brand({ logo, link }) {
	const [isHovering, setIsHovering] = React.useState(false);
	const state = isHovering ? `opacity-100 visibile` : `opacity-0 invisible`;
	return (
		<article
			className="w-4/12 p-16 md:w-1/2 md:px-10 md:mb-10 xxl:px-20 transition-all duration-200 ease-out hover:shadow-xl outline-none"
			onMouseEnter={() => setIsHovering(true)}
			onMouseLeave={() => setIsHovering(false)}
		>
			<a href={link} className="outline-none" target="_blank" rel="noopener noreferrer">
				<Img fluid={logo} loading="eager"/>
				<div
					className={`button-container lg:hidden ${state} text-center transition-opacity duration-300 ease-out`}
				>
					<button className={`relative xxl:text-2xl py-2 px-4`}>
						Learn More
						<span className={`bg-black h-full w-05 absolute top-0 left-0 transform transition-all duration-300 ease-out  hover:bg-white ${isHovering ? `translate-y-0 delay-1000` : `-translate-y-full`}`} />
						<span className={`bg-black h-full w-05 absolute bottom-0 right-0 transform transition-all duration-300 ease-out  hover:bg-white ${isHovering ? `translate-y-0 delay-1000` : `translate-y-full`}`} />
						<span className={`bg-black w-full h-05 absolute top-0 right-0 transform transition-all duration-300 ease-out  hover:bg-white ${isHovering ? `translate-x-0 delay-1000` : `-translate-x-full`}`} />
						<span className={`bg-black w-full h-05 absolute bottom-0 left-0 transform transition-all duration-300 ease-out  hover:bg-white ${isHovering ? `translate-x-0 delay-1000` : `translate-x-full`}`} />
					</button>
				</div>
			</a>
		</article>
	);
}
