import React, { Component } from 'react';
import * as Icons from '../../Icons/';
import styles from './index.module.scss';
import Fade from 'react-reveal/Fade';
import Img from 'gatsby-image';
import * as Button from '../../Button';
import Intro from './Intro';
import ClientsSection from './Clients';

export default class Home extends Component {
	render() {
		const { images } = this.props;
		return (
			<div>
				<Intro />
				<ClientsSection />
				<section className="lg:h-full lg:flex-col flex h-screen" id="work">
					<Fade bottom>
						<div className="xxl:px-24 lg:w-full lg:px-20 md:px-10 lg:order-2 self-center w-5/12 px-16 py-32">
							<h3 className="captions font-title mb-8 font-bold uppercase">
								Vectr Ventures
							</h3>
							<p className="body mb-8">Excellence through quailty</p>
							<p className="body mb-12">
								We Help Vectr Company with a complete tailor made solution to
								show their portfolio with creative animations.
							</p>
							<p className="body mb-16">
								Founded in 2013, Vectr seeks to partner with globally-minded
								entrepreneurs who possess a passion for creating disruptive
								products that connect the old and new guard. Our team strives to
								the ideal partner for founders with an eye for growth in
								Asia.
							</p>
							<a target="_blank" ref="noopener noreferrer" href="https://vectr.co">
								<Button.Tertiary>Learn More</Button.Tertiary>
							</a>
						</div>
					</Fade>
					<Fade big>
						<div
							className={`w-7/12 bg-brand-gray lg:w-full md:h-auto lg:order-1 ${styles.sectionBg}`}
						>
							<div className="image-container h-full">
								<Img
									fluid={images.vectr.childImageSharp.fluid}
									className="w-full h-full"
								/>
							</div>
						</div>
					</Fade>
				</section>
				<section className="lg:h-full lg:flex-col flex h-screen">
					<Fade big>
						<div
							className={`w-7/12 bg-brand-gray lg:w-full ${styles.sectionBg}`}
						>
							<div className="image-container h-full">
								<Img
									fluid={images.bmw.childImageSharp.fluid}
									className="w-full h-full"
								/>
							</div>
						</div>
					</Fade>
					<Fade bottom>
						<div className="xxl:px-24 lg:w-full lg:px-20 md:px-10 self-center w-5/12 px-16 py-32">
							<h3 className="captions font-title mb-8 font-bold uppercase">
								Along with BMW
							</h3>
							<p className="body mb-8">
								Classic marketing web medium for BMW's new brand statement.
							</p>
							<p className="body mb-16">
								Built a new site that communicates BMW's brand statement through
								a series of articles. We are responsible for the web development
								and maintenance.
							</p>
							<a target="_blank" ref="noopener noreferrer" href="https://along.bmwhk.com/">
								<Button.Tertiary>Learn More</Button.Tertiary>
							</a>
						</div>
					</Fade>
				</section>
				<section className="lg:h-full lg:flex-col flex h-screen" id="work">
					<Fade bottom>
						<div className="xxl:px-24 lg:w-full lg:px-20 md:px-10 lg:order-2 self-center w-5/12 px-16 py-32">
							<h3 className="captions font-title mb-8 font-bold uppercase">
								Fuin Real Estates
							</h3>
							<p className="body mb-8">Excellence through quailty. Give Meaning to Technology.</p>
							<p className="body mb-12">
								A smart CRM that just makes sense and reflect contemporary
								style.
							</p>
							<p className="body mb-16">
								Fuin creates exceptional living spaces for the most discerning.
								As a curator of the ultimate refined lifestyle, the Fuin
								experience is grounded in real estate and aspires to be present
								in all elements of our daily lives.
							</p>
							<a target="_blank" ref="noopener noreferrer" href="https://fuinrealestate.com/">
								<Button.Tertiary>Learn More</Button.Tertiary>
							</a>
						</div>
					</Fade>
					<Fade big>
						<div
							className={`w-7/12 bg-brand-gray lg:w-full md:h-auto lg:order-1 ${styles.sectionBg}`}
						>
							<div className="image-container h-full">
								<Img
									fluid={images.fuin.childImageSharp.fluid}
									className="w-full h-full"
								/>
							</div>
						</div>
					</Fade>
				</section>
				<section className="bg-brand-gray lg:px-20 md:px-10 flex h-screen" id="about">
					<Fade big>
						<div className="self-center max-w-6xl mx-auto text-center">
							<h3 className="title mb-8 uppercase">
								More about us,
								<br />
								our principles and values
							</h3>
							<p className="body mb-8">
								We are a team of talents brought together in order to solve
								problems through technology. Our passion and integrity to solve
								problems help us win key clients globally.
							</p>
							<p className="body mb-16">
								It would be our pleasure to speak with you over coffee <span role="img" aria-label="smile">🙂</span> 
							</p>
							<a target="_blank" ref="noopener noreferrer" href="mailto:info@astronlab.io">
								<Button.Primary>Contact Us Now</Button.Primary>
							</a>
						</div>
					</Fade>
				</section>
				<section className="flex py-32">
					<div className="self-center w-10/12 mx-auto text-center">
						<Fade big>
							<h3 className="title mb-32 uppercase">Services</h3>
						</Fade>
						<div className="flex flex-wrap -mx-2">
							<Fade bottom>
								<div className="md:w-full w-1/3 px-2">
									<div className="flex flex-col justify-center py-20">
										<div className="brand-blue w-32 m-auto">
											<Icons.Development />
										</div>
										<h5 className="large-text">Website Development</h5>
									</div>
								</div>
								<div className="md:w-full w-1/3 px-2">
									<div className="flex flex-col justify-center py-20">
										<div className="brand-blue w-32 m-auto">
											<Icons.Ecommerce />
										</div>
										<h5 className="large-text">E-Commerce</h5>
									</div>
								</div>
								<div className="md:w-full w-1/3 px-2">
									<div className="flex flex-col justify-center py-20">
										<div className="brand-blue w-32 m-auto">
											<Icons.Hosting />
										</div>
										<h5 className="large-text">Hosting</h5>
									</div>
								</div>
								<div className="md:w-full w-1/3 px-2">
									<div className="flex flex-col justify-center py-20">
										<div className="brand-blue w-32 m-auto">
											<Icons.Consulting />
										</div>
										<h5 className="large-text">IT Consulting</h5>
									</div>
								</div>
								<div className="md:w-full w-1/3 px-2">
									<div className="flex flex-col justify-center py-20">
										<div className="brand-blue w-32 m-auto">
											<Icons.Enterprise />
										</div>
										<h5 className="large-text">Enterprise Platform</h5>
									</div>
								</div>
								<div className="md:w-full w-1/3 px-2">
									<div className="flex flex-col justify-center py-20">
										<div className="brand-blue w-32 m-auto">
											<Icons.DataAnalytics />
										</div>
										<h5 className="large-text">Data Analytics</h5>
									</div>
								</div>
							</Fade>
						</div>
					</div>
				</section>
			</div>
		);
	}
}
