import React, { Component } from 'react';
import styles from './index.module.scss';
import Opening from './Opening';
import gsap from 'gsap';

export default class Intro extends Component {
	constructor(props) {
		super(props);
		this.canvas = null;
		this.tl = null;
		this.btnText = null;
		this.content = null;
		this.btnLineHT = null;
		this.btnLineHB = null;
		this.btnLineVL = null;
		this.btnLineVR = null;
	}
	componentDidMount() {
		this.tl = gsap.timeline().to(this.content, 1.2, {
				autoAlpha: 1,
		
			})
			.to(this.btnText, { autoAlpha: 1,duration: 0.5 }, '-=0.5')
			.fromTo(
				this.btnLineVL,
				{ height: '0' },
				{ height: '100%',duration: 0.5 }
			)
			.fromTo(
				this.btnLineVR,
				{ height: '0' },
				{ height: '100%',duration: 0.5 },
				'-=0.5'
			)
			.fromTo(
				this.btnLineHT,
				{ width: '0' },
				{ width: '100%',duration: 0.5 },
				'-=0.5'
			)
			.fromTo(
				this.btnLineHB,
				{ width: '0' },
				{ width: '100%',duration: 0.5 },
				'-=0.5'
			)
			.delay(3.5);
	}
	// componentDidMount() {
	// 	(function() {
	// 		let requestAnimationFrame =
	// 			window.requestAnimationFrame ||
	// 			window.mozRequestAnimationFrame ||
	// 			window.webkitRequestAnimationFrame ||
	// 			window.msRequestAnimationFrame ||
	// 			function(callback) {
	// 				window.setTimeout(callback, 1000 / 60);
	// 			};
	// 		window.requestAnimationFrame = requestAnimationFrame;
	// 	})();

	// 	// Terrain stuff.
	// 	let terrain = document.getElementById('terCanvas'),
	// 		background = document.getElementById('bgCanvas'),
	// 		terCtx = terrain.getContext('2d'),
	// 		bgCtx = background.getContext('2d'),
	// 		width = window.innerWidth,
	// 		height = document.body.offsetHeight;

	// 	terrain.width = background.width = width;
	// 	terrain.height = background.height = height;

	// 	// Some random points
	// 	let points = [],
	// 		displacement = 140,
	// 		power = Math.pow(2, Math.ceil(Math.log(width) / Math.log(2)));

	// 	// set the start height and end height for the terrain
	// 	points[0] = height - (Math.random() * height) / 2 - displacement;
	// 	points[power] = height - (Math.random() * height) / 2 - displacement;

	// 	// create the rest of the points
	// 	for (var i = 1; i < power; i *= 2) {
	// 		for (var j = power / i / 2; j < power; j += power / i) {
	// 			points[j] =
	// 				(points[j - power / i / 2] + points[j + power / i / 2]) / 2 +
	// 				Math.floor(Math.random() * -displacement + displacement);
	// 		}
	// 		displacement *= 0.6;
	// 	}

	// 	// Second canvas used for the stars
	// 	bgCtx.fillStyle = '#05004c';
	// 	bgCtx.fillRect(0, 0, width, height);

	// 	// stars
	// 	function Star(options) {
	// 		this.size = Math.random() * 2;
	// 		this.speed = Math.random() * 0.1;
	// 		this.x = options.x;
	// 		this.y = options.y;
	// 	}

	// 	Star.prototype.reset = function() {
	// 		this.size = Math.random() * 2;
	// 		this.speed = Math.random() * 0.1;
	// 		this.x = width;
	// 		this.y = Math.random() * height;
	// 	};

	// 	Star.prototype.update = function() {
	// 		this.x -= this.speed;
	// 		if (this.x < 0) {
	// 			this.reset();
	// 		} else {
	// 			bgCtx.fillRect(this.x, this.y, this.size, this.size);
	// 		}
	// 	};

	// 	function ShootingStar() {
	// 		this.reset();
	// 	}

	// 	ShootingStar.prototype.reset = function() {
	// 		this.x = Math.random() * width;
	// 		this.y = 0;
	// 		this.len = Math.random() * 80 + 10;
	// 		this.speed = Math.random() * 10 + 6;
	// 		this.size = Math.random() * 1 + 0.1;
	// 		// this is used so the shooting stars arent constant
	// 		this.waitTime = new Date().getTime() + Math.random() * 3000 + 500;
	// 		this.active = false;
	// 	};

	// 	ShootingStar.prototype.update = function() {
	// 		if (this.active) {
	// 			this.x -= this.speed;
	// 			this.y += this.speed;
	// 			if (this.x < 0 || this.y >= height) {
	// 				this.reset();
	// 			} else {
	// 				bgCtx.lineWidth = this.size;
	// 				bgCtx.beginPath();
	// 				bgCtx.moveTo(this.x, this.y);
	// 				bgCtx.lineTo(this.x + this.len, this.y - this.len);
	// 				bgCtx.stroke();
	// 			}
	// 		} else {
	// 			if (this.waitTime < new Date().getTime()) {
	// 				this.active = true;
	// 			}
	// 		}
	// 	};

	// 	let entities = [];

	// 	// init the stars
	// 	for (var i = 0; i < height; i++) {
	// 		entities.push(
	// 			new Star({ x: Math.random() * width, y: Math.random() * height })
	// 		);
	// 	}

	// 	// Add 2 shooting stars that just cycle.
	// 	entities.push(new ShootingStar());
	// 	entities.push(new ShootingStar());

	// 	//animate background
	// 	function animate() {
	// 		bgCtx.fillStyle = '#05004c';
	// 		bgCtx.fillRect(0, 0, width, height);
	// 		bgCtx.fillStyle = '#ffffff';
	// 		bgCtx.strokeStyle = '#ffffff';

	// 		let entLen = entities.length;

	// 		while (entLen--) {
	// 			entities[entLen].update();
	// 		}

	// 		requestAnimationFrame(animate);
	// 	}
	// 	animate();
	// }
	render() {
		return (
			<>
				<header className={`fullHeight flex relative ${styles.header}`}>
					<div
						className="w-2/3 self-center mx-40 text-white opacity-0 md:w-full md:mx-10"
						ref={content => (this.content = content)}
					>
						<h1 className="headline mb-12 md:mb-16">Building Experiences.</h1>
						<p className="large-text mb-16 md:text-3xl">
							We are an agile team providing technology service solutions.
							Taking care of <br className="hidden xxl:block"/> our client's needs is the core of our philosophy.
						</p>
						<p className="large-text mb-20 xxl:mb-32 md:text-3xl">
							Have a project in mind? We’d love to chat.
						</p>
						<a href="mailto:contact@astronlab.io">
							<button
								className={`text-xl xxl:text-2xl bg-transparent px-16 py-4 text-white uppercase tracking-widest relative ${styles.btn}`}
							>
								<span
									className="bg-white h-full absolute top-0 left-0"
									ref={line => (this.btnLineVL = line)}
								/>
								<span
									className="bg-white h-full absolute bottom-0 right-0"
									ref={line => (this.btnLineVR = line)}
								/>
								<span
									className="bg-white w-full absolute top-0 right-0"
									ref={line => (this.btnLineHT = line)}
								/>
								<span
									className="bg-white w-full absolute bottom-0 left-0"
									ref={line => (this.btnLineHB = line)}
								/>
								<span className="opacity-0" ref={text => (this.btnText = text)}>
									Get In Touch
								</span>
							</button>
						</a>
					</div>
					<Opening />
					{/* <canvas
						id="bgCanvas"
						className="absolute w-full h-full top-0 left-0 -z-10"
					></canvas>
					<canvas
						id="terCanvas"
						className="absolute w-full h-full top-0 left-0 -z-10 opacity-0"
					></canvas> */}
				</header>
			</>
		);
	}
}
