import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { graphql } from 'gatsby';

import Home from '../components/pages/Home';

const IndexPage = ({ data }) => (
	<Layout>
		<SEO title="Home" />
		<Home images={data} />
	</Layout>
);

export const query = graphql`
	query HomeImgQuery {
		vectr: file(name: { eq: "project-vectr" }) {
			childImageSharp {
				fluid(maxWidth: 4000, quality: 60) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		fuin: file(name: { eq: "project-fuin" }) {
			childImageSharp {
				fluid(maxWidth: 4000, quality: 60) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		bmw: file(name: { eq: "project-bmw" }) {
			childImageSharp {
				fluid(maxWidth: 4000, quality: 60) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
	}
`;

export default IndexPage;
