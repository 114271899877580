import React, { Component } from 'react';
import styles from './index.module.scss';
import { Sine, TimelineMax } from 'gsap';

export default class Opening extends Component {
	constructor(props) {
		super(props);
		this.canvas = null;
		this.logo = null;
		this.tl = null;
		this.bg = null;
	}
	componentDidMount() {
		if (typeof window !== 'undefined') {
			this.tl = new TimelineMax()
				.to(this.logo, 1.5, { autoAlpha: 1, ease: Sine.easeInOut })
				.to(this.logo, 1, {
					css: {
						width: '4rem',
						top: '2.68rem',
						left: '4rem',
						transform: 'none'
					},
					ease: Sine.easeInOut
				})
				.to(
					this.bg,
					1.5,
					{
						autoAlpha: 0,
						ease: Sine.easeInOut
					},
					'-=.5'
				);

			const canvas = this.canvas;
			const c = canvas.getContext('2d');

			canvas.width = window.innerWidth;
			canvas.height = window.innerHeight;

			const particleCount = 750;
			const mouse = {
				x: window.innerWidth / 2,
				y: window.innerHeight / 2
			};

			window.addEventListener('mousemove', function(event) {
				mouse.x = event.clientX - canvas.width / 2;
				mouse.y = event.clientY - canvas.height / 2;
			});

			window.addEventListener('resize', function() {
				canvas.width = window.innerWidth;
				canvas.height = window.innerHeight;

				lightParticles = [];
				initializeParticles();
			});

			function LightParticle(x, y, radius, color) {
				this.x = x;
				this.y = y;
				this.radius = radius;
				this.color = color;

				this.update = function() {
					this.draw();
				};

				this.draw = function() {
					c.save();
					c.beginPath();
					c.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
					c.shadowColor = this.color;
					c.shadowBlur = 15;
					c.shadowOffsetX = 0;
					c.shadowOffsetY = 0;
					c.fillStyle = this.color;
					c.fill();
					c.closePath();
					c.restore();
				};
			}

			let lightParticles = [];

			let timer = 0;
			let opacity = 1;
			let speed = 0.0005;
			let colors = ['#0952BD', '#A5BFF0', '#118CD6', '#1AAEE8', '#F2E8C9'];

			let initializeParticles;

			(initializeParticles = function() {
				for (let i = 0; i < particleCount; i++) {
					let randomColorIndex = Math.floor(Math.random() * 6);
					let randomRadius = Math.random() * 2;

					// Ensure particles are spawned past screen width and height so
					// there will be no missing stars when rotating canvas
					let x =
						Math.random() * (canvas.width + 200) - (canvas.width + 200) / 2;
					let y =
						Math.random() * (canvas.width + 200) - (canvas.width + 200) / 2;
					lightParticles.push(
						new LightParticle(x, y, randomRadius, colors[randomColorIndex])
					);
				}
			})();

			function animate() {
				window.requestAnimationFrame(animate);

				c.save();

				if (isMouseDown === true) {
					// Ease into the new opacity
					let desiredOpacity = 0.01;
					opacity += (desiredOpacity - opacity) * 0.03;
					c.fillStyle = 'rgba(18, 18, 18,' + opacity + ')';

					// Ease into the new speed
					let desiredSpeed = 0.012;
					speed += (desiredSpeed - speed) * 0.01;
					timer += speed;
				} else {
					// Ease back to the original opacity
					let originalOpacity = 1;
					opacity += (originalOpacity - opacity) * 0.01;
					c.fillStyle = 'rgba(18, 18, 18, ' + opacity + ')';

					// Ease back to the original speed
					let originalSpeed = 0.001;
					speed += (originalSpeed - speed) * 0.01;
					timer += speed;
				}

				c.fillRect(0, 0, canvas.width, canvas.height);
				c.translate(canvas.width / 2, canvas.height / 2);
				c.rotate(timer);

				for (let i = 0; i < lightParticles.length; i++) {
					lightParticles[i].update();
				}

				c.restore();
			}

			let isMouseDown = false;

			if (window.innerWidth > 768) {
				setTimeout(() => {
					isMouseDown = true;
				}, 7000);

				setInterval(() => {
					if (isMouseDown) {
						isMouseDown = false;
					} else {
						isMouseDown = true;
					}
				}, 20000);
			}

			animate();
		}
	}
	render() {
		return (
			<>
				<div
					className={`absolute w-full h-full top-0 left-0 ${styles.opening}`}
					ref={bg => (this.bg = bg)}
				>
					<svg
						viewBox="0 0 718.071 691.063"
						className="fill-current text-white w-64 abs-center fixed opacity-0"
						ref={logo => (this.logo = logo)}
					>
						<g transform="translate(-547.076 -2012.687)">
							<path d="M1265.147,2017.4v686.352H853.418V2612.7l274.781-1.177-.578-594.121Z" />
							<path d="M870.246,2045.028l-14.9-32.342-14.461,31.925-293.81,654.424H693.568l44.273-97.914,2.127-5.123H864.414L802.094,2459.1l.217-.218h-.6l54.076-120.049,53.637,120.049h-.236l109.326,240.148h145.957Z" />
						</g>
					</svg>
				</div>
				<canvas
					className="absolute w-full h-full top-0 left-0 -z-10"
					ref={canvas => (this.canvas = canvas)}
				/>
			</>
		);
	}
}
